import React, { Component, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Col, Row, Label, Input } from 'reactstrap';
import { FormGroup } from '@mui/material';
import { getOrderList } from '../../actions/admin'
import { getOrderHistory, getAllOrderList } from '../../actions/admin'
// import FormControl from '@mui/material/FormControl';
import { Select } from '@mui/material';
import Input1 from '@mui/material/Input';
import { connect } from 'react-redux';
import axios from 'axios';
import AppConfig from 'constants/config'
import moment from 'moment';
// import { DatePicker } from 'material-ui-pickers';
import { MenuItem } from '@mui/material';
import './index.css'
import CustomeFilterForDate from 'app/routes/components/CustomFilter/customfilter';
import { useNavigate } from 'react-router';

const monthArray = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const OpenOrdersStatusnames = ["New", "Processed", "Requested", "OnHold"]

const payment_mode = ['Cod', "Prepaid", "Bulk Prepaid"]
const payment_type = ["COD", "Online", "Bulk"]

const names = ["New", "Processed", "Requested", "Pending", "Cancelled", "Delivered", "OnHold", "Not Picked", "RTO"]
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

let date = new Date();

function CustomFilter(props) {
  const history = useNavigate()
  let url = window.location.search
  let json = url ? JSON.parse('{"' + decodeURIComponent(url.substring(1).replace(/&/g, "\",\"").replace(/=/g, "\":\"").replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')) + '"}') : ''
  let Sval = json ? json.statusO ? json.statusO : json.statusOH ? json.statusOH : json.statusA ? json.statusA : '' : '';
  const sSval = Sval ? Sval.split(',') : [];
  let stype = json ? json.typeO ? json.typeO : json.typeOH ? json.typeOH : json.typeA ? json.typeA : '' : ''
  const sType = stype ? stype.split(',') : [];


  const [state, setState] = useState({
    month: '',
    year: 0,
    yearList: [],
    status: sSval,
    filterData: '',
    filterDataB: '',
    seller_id: json ? json.seller_idO ? json.seller_idO : json.seller_idOH ? json.seller_idOH : json.seller_idA ? json.seller_idA : '' : '',
    buyer_id: json ? json.buyer_idO ? json.buyer_idO : json.buyer_idOH ? json.buyer_idOH : json.buyer_idA ? json.buyer_idA : '' : '',
    selectedDate: json ? json.from_dateO ? json.from_dateO : json.from_dateOH ? json.from_dateOH : json.from_dateA ? json.from_dateA : moment().subtract(15, 'days').format() : moment().subtract(15, 'days').format(),
    selectedDateAll: json ? json.from_dateA ? json.from_dateA : moment().subtract(2, 'months') : moment().subtract(2, 'months'),
    selectedDated: '',
    selectedDateTo: json ? json.to_dateO ? json.to_dateO : json.to_dateOH ? json.to_dateOH : json.to_dateA ? json.to_dateA : moment().format() : moment().format(),
    from: json ? json.from_dateO ? json.from_dateO : json.from_dateOH ? json.from_dateOH : json.from_dateA ? json.from_dateA : moment().subtract(15, 'days').format() : moment().subtract(15, 'days').format(),
    to: json ? json.to_dateO ? json.to_dateO : json.to_dateOH ? json.to_dateOH : json.to_dateA ? json.to_dateA : moment().format() : moment().format(),
    type: sType,
    orderType: json ? json.orderTypeO ? json.orderTypeO : json.orderTypeOH ? json.orderTypeOH : json.orderTypeA ? json.orderTypeA : '' : ''
  })

  const fetchData = async () => {
    setState((prev) => ({
      ...prev,
      month: props.month ? props.month : monthArray[date.getMonth() - 1], year: props.year ? props.year : date.getFullYear(),
    }))

    if (props.filterFor === 'OrderList' || 'orderHistoryAdmin') {
      await axios({
        method: 'get',
        url: `${AppConfig.baseUrl}inventory/get_filters?user_type=admin`,
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('admin_token')
        }
      }
      ).then(result => {
        if (result.data.error) {
          console.log("error", result.data)
        } else {
          setState((prev) => ({
            ...prev,
            filterData: result.data.detail.sellers,
            filterDataB: result.data.detail.buyers ? result.data.detail.buyers : ''
          }))

        }
      })
        .catch(error => {
          console.log("error catch", error)
        });
    }
  }
  useEffect(() => {
    fetchData()
  }, [window.location.search])

  const handleSelectChange = (e) => {
    let tempSelectedCat = e.target.value;
    let index = tempSelectedCat && tempSelectedCat.length > 0 ? tempSelectedCat.findIndex((e) => e === 'status') > -1 ? tempSelectedCat.findIndex((e) => e === 'status') : -1 : -1;
    index > -1 && tempSelectedCat.splice(index, 1);
    setState((prev) => ({ ...prev, status: tempSelectedCat }));
    props.onFilterChange(e, 'status');

  }
  const handleSelectChangePaymentType = (e) => {
    let tempSelectedCat = e.target.value;
    let index = tempSelectedCat && tempSelectedCat.length > 0 ? tempSelectedCat.findIndex((e) => e === 'type') > -1 ? tempSelectedCat.findIndex((e) => e === 'type') : -1 : -1;
    index > -1 && tempSelectedCat.splice(index, 1);
    setState((prev) => ({ ...prev, type: tempSelectedCat }));
    props.onFilterChange(e, 'type');

  }

  const handleChange = (e, key) => {
    setState((prev) => ({ ...prev, [key]: e.target.value }));
    props.onFilterChange(e, key);
  }

  const handleChanged = (e, key) => {
    setState((prev) => ({ ...prev, [key]: e.target.value }));
    props.onFilterChange(e, key);
  }

  const handleClose = e => {
    e.preventDefault();
    setState((prev) => ({ ...prev, selectedDated: '' }))
    props.handleResetFilter(e, props.applyFilter)
  }

  const handleSubmit = e => {
    e.preventDefault();
    props.applyFilter();
    if (props.filterFor === 'OrderList' || props.filterFor === 'orderHistoryAdmin') {
      props.sellerFromFilter(state.seller_id)
      props.dateFilterFrom(state.from)
      props.dateFilterTo(state.to)
    } else if (props.filterFor === 'AllOrderList') {
      props.buyerFromFilter(state.buyer_id)
      props.sellerFromFilter(state.seller_id)
      props.dateFilterFrom(state.from)
      props.dateFilterTo(state.to)
    }
    if (props.filterFor === 'OrderList') {
      let obj = { from_dateO: state.from, to_dateO: state.to, pageO: 0, searchO: props.searchText, statusO: state.status, seller_idO: state.seller_id, typeO: state.type, orderTypeO: state.orderType }
      let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
      history(`/admin/orders?${url}`)
    } else if (props.filterFor === 'orderHistoryAdmin') {
      let obj = { from_dateOH: state.from, to_dateOH: state.to, pageOH: 0, searchOH: props.searchText, statusOH: state.status, seller_idOH: state.seller_id, typeOH: state.type, orderTypeOH: state.orderType }
      let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
      history(`/admin/orders?${url}`)
    } else if (props.filterFor === 'AllOrderList') {
      let obj = { from_dateA: state.selectedDateAll, to_dateA: state.to, pageA: 0, searchA: props.searchText, statusA: state.status, seller_idA: state.seller_id, buyer_idA: state.buyer_id, typeA: state.type, orderTypeA: state.orderType }
      let url = Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
      history(`/admin/orders?${url}`)
    }
  }
  const handleDateChange = (date, key) => {
    setState((prev) => ({ ...prev, [key]: moment(date).format() }))
    if ([key] == 'from') {
      setState((prev) => ({ ...prev, selectedDate: moment(date).format() }))
    }
    if ([key] == 'to') {
      setState((prev) => ({ ...prev, selectedDateTo: moment(date).format() }))
    }
  }
  const handleDateChanged = (date, key) => {
    if ([key] == 'from') {
      setState((prev) => ({ ...prev, selectedDateAll: moment(date).format() }))
    }
    if ([key] == 'to') {
      setState((prev) => ({ ...prev, [key]: moment(date).format() }))
    }
  }

  let { status, filterData, filterDataB, seller_id, selectedDate, buyer_id, selectedDated, selectedDateTo, type, selectedDateAll, orderType } = state;
  return (
    <React.Fragment >
      <div form style={{ width: '450px' }}>
        <Row form>
          {props.filterFor == 'AllOrderList' ? ''
            :
            <React.Fragment>
              <CustomeFilterForDate handleDateChange={handleDateChange} to={selectedDateTo} from={selectedDate} />
              {/* <Col md={6} xl={6} xs={12} sm={12} lg={6}>
                  <FormControl className="w-100 mb-2 ">
                    <Label for="fromDate">FROM</Label>
                    <DatePicker
                      onChange={(date) => handleDateChange(date, 'from')}
                      name='from'
                      id="from"
                      value={selectedDate}
                      // autoOk
                      fullWidth
                      leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                      rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                      format="DD/MM/YYYY"
                    />
                  </FormControl>
                </Col>

                <Col md={6} xl={6} xs={12} sm={12} lg={6}>

                  <FormControl >
                    <Label for="toDate">TO</Label>
                    <DatePicker
                      onChange={(date) => handleDateChange(date, 'to')}
                      name='to'
                      id="to"
                      value={selectedDateTo}
                      fullWidth
                      leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                      rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                      format="DD/MM/YYYY"
                    />
                  </FormControl>
                </Col> */}
            </React.Fragment>
          }
        </Row>
        {props.filterFor == 'orderHistoryAdmin' ?
          <Row form>
            <Col md={6} xl={6} xs={12} sm={12} lg={6}>
              <FormGroup className={'mt-1'}>
                <Label for="exampleSelect">Sellers</Label>
                <Input type="select" name="seller" id="seller"
                  value={seller_id}
                  onChange={(e) => handleChanged(e, 'seller_id')}>
                  <option selected={seller_id == ''} value={''}>Select Seller</option>
                  {
                    filterData && filterData.map((val) => {
                      return (<option selected={val._id == seller_id} value={val._id}>{val.company_name}</option>)
                    })
                  }
                </Input>
              </FormGroup>
            </Col>
            <Col md={6} xl={6} xs={12} sm={12} lg={6}>
              <FormGroup className={'mt-1'}>
                <Label for="exampleSelect">Status</Label>
                <Input type="select" value={status} onChange={(e) => handleChange(e, 'status')} name="status" id="status">
                  <option value="" >Select Status</option>
                  <option value="Cancelled" >Cancelled</option>
                  <option value="Delivered" >Delivered</option>
                </Input>
              </FormGroup>
            </Col>

          </Row>
          : ''
        }
        {props.filterFor == 'OrderList' ?
          <Row form>
            <Col md={6} xl={6} xs={12} sm={12} lg={6}>
              <FormGroup className={'mt-1'}>
                <Label for="exampleSelect">Sellers</Label>
                <Input type="select" name="seller" id="seller"
                  value={seller_id}
                  onChange={(e) => handleChanged(e, 'seller_id')}>
                  <option selected={seller_id == ''} value={''}>Select Seller</option>
                  {
                    filterData && filterData.map((val) => {
                      return (<option selected={val._id == seller_id} value={val._id}>{val.company_name}</option>)
                    })
                  }
                </Input>
              </FormGroup>
            </Col>
            <Col md={6} xl={6} xs={12} sm={12} lg={6}>
              <FormGroup className={'mt-1'}>
                <Label for="exampleSelect">Status</Label>
                <Select style={{ margin: "1px" }}
                  labelId="demo-multiple-checkbox-label"
                  multiple
                  id="demo-multiple-checkbox-label"
                  value={status}
                  onChange={(e) => handleSelectChange(e)}
                  input={<Input1 disableUnderline={true} className="form-control " id="name-multiple" />}
                  MenuProps={MenuProps}
                >
                  <MenuItem
                    key={'select status'}
                    value={'status'}
                    style={{
                      fontWeight: '500',
                    }}
                  >
                    Select Status
                  </MenuItem>
                  {OpenOrdersStatusnames && OpenOrdersStatusnames.map(name => (
                    <MenuItem
                      key={name}
                      value={name}
                      className='putIconAfter '
                      style={{
                        fontWeight: status?.indexOf(name) !== -1 ? '500' : '400',
                      }}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>

              </FormGroup>
            </Col>
          </Row>
          : ''
        }
        {props.filterFor == 'AllOrderList' ?
          <React.Fragment>
            <Row>
              <CustomeFilterForDate handleDateChange={handleDateChanged} to={selectedDateTo} from={selectedDateAll} />
            </Row>

            <Row>
              <Col md={4} xl={4} xs={12} sm={12} lg={4}>
                <FormGroup className={'mt-1'}>

                  <Label for="exampleSelect">Status</Label>


                  <Select style={{ margin: "1px" }}
                    labelId="demo-multiple-checkbox-label"
                    multiple
                    id="demo-multiple-checkbox-label"
                    value={status}
                    onChange={(e) => handleSelectChange(e)}
                    input={<Input1 disableUnderline={true} className="form-control " id="name-multiple" />}
                    MenuProps={MenuProps}
                  >
                    <MenuItem
                      key={'select status'}
                      value={'status'}
                      style={{
                        fontWeight: '500',
                      }}
                    >
                      Select Status
                    </MenuItem>
                    {names && names.map(name => (
                      <MenuItem
                        key={name}
                        value={name}
                        className='putIconAfter '
                        style={{
                          fontWeight: state.status.indexOf(name) !== -1 ? '500' : '400',
                        }}
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormGroup >
              </Col>

              <Col md={4} xl={4} xs={12} sm={12} lg={4}>
                <FormGroup className={'mt-1'}>

                  <Label for="exampleSelect">Payment Mode</Label>


                  <Select style={{ margin: "1px" }}
                    labelId="demo-multiple-checkbox-label"
                    multiple
                    id="demo-multiple-checkbox-label"
                    value={type}
                    onChange={(e) => handleSelectChangePaymentType(e)}
                    input={<Input1 disableUnderline={true} className="form-control " id="name-multiple" />}
                    MenuProps={MenuProps}
                  >
                    <MenuItem
                      key={'select type'}
                      value={'type'}
                      style={{
                        fontWeight: '500',
                      }}
                    >
                      Select payment mode
                    </MenuItem>
                    {payment_mode && payment_mode.map((name, i) => (
                      <MenuItem
                        key={name}
                        value={payment_type[i]}
                        className='putIconAfter '
                        style={{
                          fontWeight: state.type.indexOf(name) !== -1 ? '500' : '400',
                        }}
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormGroup >

              </Col>
              <Col md={4} xl={4} xs={12} sm={12} lg={4}>
                <FormGroup className={'mt-1'}>
                  <Label for="toDate">Order Type</Label>
                  <Input type="select" value={orderType} onChange={(e) => handleChange(e, 'orderType')} name="orderType" id="orderType">
                    <option value="">Select Type</option>
                    <option value="Medical and Surgical">Medical and Surgical</option>
                    <option value="Cool chain">Cool Chain</option>
                    <option value="Ethical branded">Ethical branded</option>
                    <option value="Generic">Generic</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6} xl={6} xs={12} sm={12} lg={6}>
                <FormGroup className={'mt-1'}>
                  <Label for="exampleSelect">Buyers</Label>
                  <Input type="select" name="seller" id="seller"
                    value={buyer_id}
                    onChange={(e) => handleChanged(e, 'buyer_id')}>
                    <option selected={buyer_id == ''} value={''}>Select Buyer</option>
                    {
                      filterDataB && filterDataB.map((val) => {
                        return (<option selected={val._id == buyer_id} value={val._id}>{val.company_name}</option>)
                      })
                    }
                  </Input>
                </FormGroup>
              </Col>
              <Col md={6} xl={6} xs={12} sm={12} lg={6}>
                <FormGroup className={'mt-1'}>
                  <Label for="exampleSelect">Sellers</Label>
                  <Input type="select" name="seller" id="seller"
                    value={seller_id}
                    onChange={(e) => handleChanged(e, 'seller_id')}>
                    <option selected={seller_id == ''} value={''}>Select Seller</option>
                    {
                      filterData && filterData.map((val) => {
                        return (<option selected={val._id == seller_id} value={val._id}>{val.company_name}</option>)
                      })
                    }
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </React.Fragment>
          : ''
        }
        {props.filterFor != 'AllOrderList' ?
          <Row form>
            <Col md={6} xl={6} xs={12} sm={12} lg={6}>
              <FormGroup className={'mt-1'}>
                <Label for="exampleSelect">Type</Label>
                <Input type="select" value={type} onChange={(e) => handleChange(e, 'type')} name="type" id="type">
                  <option value="" >Select Type</option>
                  <option value="BNPL" >BNPL</option>
                  <option value="Bulk" >Bulk Prepaid</option>
                  <option value="COD" >COD</option>
                  <option value="Online" >Prepaid</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={6} xl={6} xs={12} sm={12} lg={6}>
              <FormGroup className={'mt-1'}>
                <Label for="toDate">Order Type</Label>
                <Input type="select" value={orderType} onChange={(e) => handleChange(e, 'orderType')} name="orderType" id="orderType">
                  <option value="">Select Type</option>
                  <option value="Medical and Surgical">Medical and Surgical</option>
                  <option value="Cool chain">Cool Chain</option>
                  <option value="Ethical branded">Ethical branded</option>
                  <option value="Generic">Generic</option>
                </Input>
              </FormGroup>
            </Col>
          </Row> : ''}
        <div style={{ paddingTop: 15 }} className="row" >
          <Col md={6} xl={6} xs={12} sm={12} lg={6}>
            <Button variant="contained" className='filterButton' onClick={(e) => handleSubmit(e)} color='primary'>Apply Filter</Button>
          </Col>
          <Col md={6} xl={6} xs={12} sm={12} lg={6} className="text-nowrap">
            <Button variant="contained" onClick={(e) => handleClose(e)} className='filterButton' color='primary'>Reset Filter</Button>
          </Col>
        </div>
      </div>
    </React.Fragment>
  );
}


export default connect(null, { getOrderList, getOrderHistory, getAllOrderList })(CustomFilter);
